<template>
    <div
      v-loading="!entity.BillId"
      :style="{ background: Status == 3 ? 'rgb(255 239 239)' : '' }"
    >
      <div style="display: flex">
        <div id="printDiv" :style="`width: ${styleW}cm;`">
          <div
            v-for="(text, index) in newarr"
            :key="index"
            :style="`position: relative;width: ${text.styleW}cm;height: ${
              styleH * (Number(text.printPages) > 1 ? Number(text.printPages) : 1)
            }cm;page-break-after:always`"
          >
            <vue-drag-resize
              v-for="(item, index) in text.printlist"
              :key="item.Id"
              :isActive="false"
              :preventActiveBehavior="false"
              :isDraggable="false"
              :isResizable="false"
              :aspectRatio="false"
              parentLimitation
              :parentW="getpx(text.styleW, 'w', 'px')"
              :parentH="
                getpx(
                  styleH *
                    (Number(text.printPages) > 1 ? Number(text.printPages) : 1),
                  'h',
                  'px'
                )
              "
              :w="item.w"
              :h="item.h"
              :x="item.x"
              :y="item.y"
              :minh="28"
              :minw="80"
            >
              <div v-if="item.type === '7'">
                <!-- <div style="text-align: center">{{ item.Key }}</div> -->
                <vue-qr
                  :margin="0"
                  :text="QrCode"
                  :logoScale="0.2"
                  :size="item.w"
                ></vue-qr>
              </div>
              <div
                v-if="item.type === '1' || item.type === '12'"
                :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`"
              >
                <p v-if="item.Keys !== 'countnums' && item.Keys !== 'wights'">
                  {{ item.value }}
                </p>
              </div>
              <div
                v-if="item.type === '11'"
                :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`"
              >
                <p v-if="item.Keys === 'countnums'">
                  {{ item.Key }}{{ countnumsfun(text.printlist) }}
                </p>
                <p v-if="item.Keys === 'SumiInvRCost'">
                  {{ item.Key }}{{ priceNums(text.printlist) }}
                </p>
                <p v-if="item.Keys === 'wights'">
                  {{ item.Key }}{{ wightsfun(text.printlist) }}
                </p>
                <p v-if="item.Keys === 'signnums'">
                  {{ item.Key }}{{ realNums(text.printlist) }}
                </p>
                <p v-if="item.Keys === 'IdCard'">{{ item.Key }}{{ IdCard }}</p>
                <p v-if="item.Keys === 'ReceiptPhone'">
                  {{ item.Key }}{{ ReceiptPhone }}
                </p>
                <p v-if="item.Keys === 'FullName'">
                  {{ item.Key }}{{ FullName }}
                </p>
                <p v-if="item.Keys === 'SignInAddress'">
                  {{ item.Key }} {{ item.value }}
                </p>
                <p v-if="item.Keys === 'Memo'">{{ item.Key }}{{ item.value }}</p>
                <p v-if="item.Keys === 'SigningDate'">
                  {{ item.Key
                  }}{{ SigningDate ? SigningDate.substring(0, 10) : "" }}
                </p>
              </div>
  
              <div
                v-if="item.type === '5'"
                :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`"
              >
                <!-- <div
                v-if="item.Key === '制单人'"
                style="display: flex; flex-wrap: nowrap"
              >
                <p>{{ item.value }}</p>
                <img class="seal" :src="Seal" alt="" width="130" height="130" />
              </div> -->
                <div
                  v-if="item.Keys === 'Signs'"
                  style="display: flex; flex-wrap: nowrap"
                >
                  <p style="margin-right: 40px">
                    {{ item.Key }}
                  </p>
                  <div class="SginImgBox" style="flex: 1">
                    <img class="SginImg" v-show="Sign" :src="Sign" alt="" />
                  </div>
                </div>
              </div>
  
              <div
                :style="`display: flex;width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`"
                v-if="item.type === '2' || item.type === '6'"
              >
                <div style="height: 100%" :ref="item.key + item.Id">
                  {{ item.Key }}&nbsp;
                </div>
                <div
                  :style="`flex:1;height: 100%;border-bottom: 1px solid #000;text-align: ${item.align};`"
                >
                  {{ item.value }}
                </div>
              </div>
  
              <div v-if="item.type === '3'" style="width: 100%; height: 100%">
                <img :src="item.value" alt="" width="100%" height="100%" />
                <!-- <img
                style="transform: rotate(-90deg)"
                :src="Sign"
                alt=""
                width="100%"
                height="100%"
                v-if="item.Key === '手签'"
              /> -->
              </div>
  
              <div v-if="item.type === '4'" style="width: 100%; height: 100%">
                <table border="1" style="width: 100%; height: 100%">
                  <thead style="background-color: #ccc">
                    <td
                      v-for="(val, index) in item.columns"
                      :key="val.dataIndex"
                      :style="`width: ${val.width || 50}px;line-height: ${
                        val.lineHeight || 28
                      }px; height: ${val.lineHeight || 28}px; text-align: ${
                        val.align || 'center'
                      };font-weight:${val.bold || 'initial'};font-size:${
                        val.fontSize || 18
                      }px;`"
                    >
                      {{ val.title }}
                    </td>
                  </thead>
  
                  <tr v-for="(itemdata, index) in item.datalist" :key="index">
                    <td
                      v-for="(val, index) in item.columns"
                      :key="index"
                      :style="`text-align: ${val.textalign || 'center'};`"
                    >
                      <div
                        :style="`text-align: ${
                          val.textalign || 'center'
                        };height:${
                          val.textlineHeight || 20
                        }px;overflow: hidden;word-break:break-all;`"
                      >
                        {{
                          itemdata[val.dataIndex] == undefined
                            ? "&nbsp;"
                            : NumFormatting(itemdata[val.dataIndex])
                        }}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div v-if="item.type === '8'" style="width: 100%; height: 100%">
                <p
                  :style="`background: #000; width: 100%; height: ${item.pxh}px`"
                ></p>
              </div>
              <div v-if="item.type === '9'" style="width: 100%; height: 100%">
                <p
                  :style="`background: #000; width: ${item.pxh}px; height: 100%`"
                ></p>
              </div>
              <div v-if="item.type === '10'" style="width: 100%; height: 100%">
                <div
                  :style="`width: 100%; height: 100%; border: ${item.pxh}px solid #000`"
                ></div>
              </div>
            </vue-drag-resize>
          </div>
          <div v-if="isAccessory">
            <el-row>
              <el-col
                v-for="item in imglist"
                :span="8"
                :key="item"
                style="margin-bottom: 10px"
              >
                <el-image
                  style="width: 200px; height: 200px; border: 1px solid #ccc"
                  :src="item"
                  fit="contain"
                  :preview-src-list="imglist"
                >
                </el-image>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-if="Status == -1" style="padding-top: 20px; padding-left: 20px">
          <el-button @click="openEditForm">编辑</el-button><br />
          <el-button @click="confirmForm">确认</el-button>
        </div>
        <div v-if="TimeStamp" style="padding-top: 20px; padding-left: 20px">
          <div
            class="rightMain"
            :style="{ color: $route.query.TimeStamp ? '#2f98c7' : '' }"
          >
            <div><b>存证编号: </b>{{ entity.Hash }}</div>
            <div v-if="TimeStamp">
              <b>存证日期: </b>{{ timestampToTime($route.query.TimeStamp) }}
            </div>
          </div>
          <div v-show="entity.ReceiptPhone" class="rightMain">
            <h3 style="text-align: center; font-weight: bold">签收人信息</h3>
            <div><b>签收人: </b>{{ entity.FullName }}</div>
            <div><b>手机号: </b>{{ entity.ReceiptPhone }}</div>
            <!-- <div><b>身份证: </b>{{ idCardPass(entity.IdCard) }}</div> -->
            <div><b>身份证: </b>{{ entity.IdCard }}</div>
            <div><b>设备: </b>{{ entity.DeviceName }}</div>
            <div v-if="entity.IP">
              <b>{{ entity.IP.indexOf(".") !== -1 ? "IP: " : "设备ID: " }}</b
              >{{ entity.IP }}
            </div>
            <div><b>签收地址: </b>{{ entity.SignInAddress }}</div>
          </div>
          <div>
            <h3 style="text-align: center; font-weight: bold">签收</h3>
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in timeList"
                :key="index"
                :timestamp="activity.CreateTime"
              >
                <span>类型: {{ activity.Type }}</span>
                <br />
  
                <b>{{ activity.Creator }} {{ activity.Phone }}</b>
                <br />
                {{ activity.Action }}
                <br />
                <span v-show="activity.IP">IP: {{ activity.IP }}</span>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
      <div v-html="styleText"></div>
      <div style="height: 80px"></div>
     
    </div>
  </template>
  
  <script>
  import VueBarcode from "vue-barcode";
  import QRCode from "qrcodejs2";
  import VueQr from "vue-qr";
  const NumFormatting = function (data) {
    if (isNaN(Number(data))) {
      return data;
    } else {
      return Number(data).toFixed(2);
    }
  };
  const downloadFile = (url, downloadName = "") => {
    // console.log(url);
    const link = document.createElement("a");
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        link.href = URL.createObjectURL(blob);
        // console.log(link.href)
        link.download = downloadName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };
  function jsgetDPI() {
    var arrDPI = new Array();
    if (window.screen.deviceXDPI != undefined) {
      arrDPI[0] = window.screen.deviceXDPI;
      arrDPI[1] = window.screen.deviceYDPI;
    } else {
      var tmpNode = document.createElement("DIV");
      tmpNode.style.cssText =
        "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
      document.body.appendChild(tmpNode);
      arrDPI[0] = parseInt(tmpNode.offsetWidth);
      arrDPI[1] = parseInt(tmpNode.offsetHeight);
      tmpNode.parentNode.removeChild(tmpNode);
    }
    return arrDPI;
  }
  export default {
    components: {
      VueQr,
    //   VueBarcode,
    },
    data() {
      return {
        Status: 0,
        IsSubAccount: false,
        drawerloading: false,
        templatelist: [],
        isAccessory: null,
        imglist: [],
        newarr: [],
        QrCode: "",
        qrcodemodel: {}, //二维码模板,
        barcodemodel: {}, //二维码模板,
        countnums: "",
        wights: "",
        printValue: "",
        form: {},
        entity: {},
        data: [],
        allNum: 0,
        allWeight: 0,
        Sign: "",
        Seal: "",
        printObj: {
          id: "printDiv",
          popTitle: "",
        },
        printGoodsObj: {
          id: "printGoodsObj",
          popTitle: "",
        },
        printTwo: {
          id: "printTwo",
          popTitle: "",
        },
        billid: "",
        phone: "",
        code: "",
        dialogVisible: false,
        codeValue: "",
        sendMessage: "",
        dialogGoods: false,
        printId: "",
        dialogPrintVisible: false,
        printData: [], // 所有要展示的打印数据
        showPrintData: [], // 当前展示的打印数据
        printlist: [],
        printPages: "",
        styleW: "",
        styleH: "",
        printsize: "",
        styleText: "",
        printopenobj: {},
        printopenlist: [],
        qrendbar: "",
        Company: "",
        printhuopinlist: [],
        cInvStd: "",
        arrprint: [],
        FullName: "",
  
        ReceiptPhone: "",
        IdCard: "",
        pagination: {
          PageIndex: 1,
          PageRows: 10,
          total: 0,
          pageSizes: [10, 20, 30, 50],
        },
        timer: null,
        oneMinute: 60,
        disabled: false,
        codeTwo: "",
        dialogTwoVisible: false,
        danhao: "",
        cTime: "",
        smsCode: "",
        timeList: [],
        TimeStamp: "",
        SignCode: "",
      };
    },
    async created() {
      var obj = JSON.parse(sessionStorage.getItem("userInfo"));
      if (this.$route.query.token) {
        sessionStorage.setItem("token", this.$route.query.token);
      }
      if (this.$route.query.TimeStamp) {
        this.TimeStamp = this.$route.query.TimeStamp;
      }
      if (this.$route.query.uid) {
        let result = await this.$http.post("/Base_Manage/Base_User/GetTheData", {
          id: this.$route.query.uid,
        });
        // 免费及非主账户不展示
        if (
          result.Success &&
          result.Data &&
          !result.Data.IsSubAccount &&
          result.Data.AccountType != "免费"
        ) {
          this.IsSubAccount = true;
        } else {
          this.IsSubAccount = false;
        }
      } else if (obj) {
        if (obj.AccountType === "免费") {
          this.IsSubAccount = false;
        } else {
          if (!obj.IsSubAccount) {
            this.IsSubAccount = true;
          } else {
            this.IsSubAccount = false;
          }
        }
      } else {
        this.IsSubAccount = false;
      }
      if (this.$route.query.prop) {
        let arr = this.$route.query.prop.split(",");
        this.billid = arr[0];
        this.codeValue =
          location.href.split("#")[0] + "#/SendSMSMobile?id=" + this.billid;
        // this.phone = arr[1]
        // this.code = arr[2]
        await this.getFormData();
        await this.getGetQrCodeTmpList();
      }
    },
  
    methods: {
      // 确认下发
      confirmForm() {
        this.$http
          .post("/BO/BillList/AddGeneration?Id=" + this.billid)
          .then((res) => {
            if (res.Success) {
              this.getFormData();
              this.$message.success("操作成功");
            } else {
              this.$message.error(res.Msg);
            }
          });
      },
      // 编辑功能
      openEditForm() {
        this.$refs.waybillForm.openForm(this.entity, "1", this.Company, "");
      },
      // 身份证加密
      idCardPass(idCard) {
        if (idCard) {
          let a = idCard.split("");
          a.splice(6, 8, "********");
          return a.join("");
        } else {
          return "";
        }
      },
      // 下载存证
      creditFile() {
        this.$http
          .post("/BO/BillList/GetVisaPDF?id=" + this.billid)
          .then((res) => {
            console.log(this.$baseUrl);
            if (res.Success) {
              downloadFile(this.$baseUrl + "/" + res.Data);
            }
          });
      },
      // 微信分享
      handleCommand(command) {
        let baseUrl = location.href.split("#")[0];
        if (command == "单据信息") {
          this.codeValue =
            baseUrl + "#/SendSMSMobile?id=" + this.billid + "&type=0";
        } else {
          this.codeValue =
            baseUrl + "#/SendSMSMobile?id=" + this.billid + "&type=1";
        }
        this.dialogVisible = true;
      },
      datalistchange(a) {
        console.log(a);
        this.pagination.PageIndex = 1;
        this.getPrintBillListDetail(this.arrprint);
      },
      handleSizeChange(a, b) {
        this.pagination.PageRows = a;
        this.getPrintBillListDetail(this.arrprint);
        console.log(a, b);
      },
      handleCurrentChange(a, b) {
        this.pagination.PageIndex = a;
        this.getPrintBillListDetail(this.arrprint);
        console.log(a, b);
      },
      butprintclick() {
        console.log("1");
        if (this.templatelist.length === 0) {
          return this.$message.warning("请设置货品信息打印模板后打印");
        }
      },
      qrcodetext(id, arr) {
        var str = "";
        arr.forEach((item) => {
          if (item.Id === id) {
            str = item.value;
          }
        });
        return str;
      },
      getGetBillldPrintDetail(arr) {
        this.drawerloading = true;
        this.$http
          .post("/BO/BillList/GetBillldPrintDetail", {
            Search: {
              Id: this.billid,
              BarCodeList: arr,
              List: this.data[0].datalist,
            },
          })
          .then((res) => {
            if (res.Success) {
              this.printhuopinlist = res.Data;
            }
            setTimeout(() => {
              this.drawerloading = false;
            }, 100);
          });
      },
      dropdownclick(item) {
        console.log(item);
        this.cInvStd = "";
        this.arrprint = [];
        var obj = JSON.parse(item.Content);
        this.printopenobj = { ...obj };
  
        var arr = obj.printlist.filter((item) => {
          return item.Keys === "qrcode" || item.Keys === "barcode";
        });
        this.arrprint = arr;
        this.dialogGoods = true;
        this.cInvStd = this.data[0].datalist[0].Id;
        this.getPrintBillListDetail(arr);
      },
      getPrintBillListDetail(arr) {
        var obj = this.data[0].datalist.find((item) => {
          return item.Id === this.cInvStd;
        });
        var objcopy = { ...obj };
        objcopy.BillId = this.billid;
        objcopy.BarCodeList = arr;
  
        this.$http
          .post("/BO/BillList/PrintBillListDetail", {
            PageIndex: this.pagination.PageIndex,
            PageRows: this.pagination.PageRows,
            Search: objcopy,
          })
          .then((res) => {
            if (res.Success) {
              this.printhuopinlist = res.Data;
              this.pagination.total = res.Total;
            } else {
              this.$message.error(res.Msg);
            }
          });
      },
      async getGetQrCodeTmpList() {
        await this.$http
          .post("/Base/Base_Template/GetQrCodeTmpList?Id=" + this.billid, {})
          .then((res) => {
            this.templatelist = res.Data;
          });
      },
      handleDownload() {
        this.$http.post("/BO/BillList/GetPDF?id=" + this.billid).then((res) => {
          if (res.Success) {
            downloadFile(res.Data, this.billid);
          } else {
            this.$message.error(res.Msg);
          }
        });
      },
      getpx(num, wh, str) {
        var dpi = jsgetDPI();
        if (str == "px") {
          if (wh == "w") {
            return (num / 2.54) * dpi[0];
          }
          if (wh == "h") {
            return (num / 2.54) * dpi[1];
          }
        }
        if (str == "cm") {
          if (wh == "w") {
            return (num / dpi[0]) * 2.54;
          }
          if (wh == "h") {
            return (num / dpi[1]) * 2.54;
          }
        }
        // px转cm：cm = pxValue/dpi*2.54
        //           px = cm/2.54*dpi
      },
      async getqrcodemodel() {
        await this.$http
          .post(
            `/Base/Base_Template/GetCompanyTemp?CompayId=${this.Company}&type=1`,
            {}
          )
          .then((res) => {
            if (res.Success) {
              this.qrcodemodel = res.Data;
            } else {
              this.$message.error(res.Msg);
            }
          });
      },
      async getbarcodemodel() {
        await this.$http
          .post(
            `/Base/Base_Template/GetCompanyTemp?CompayId=${this.Company}&type=2`,
            {}
          )
          .then((res) => {
            if (res.Success) {
              this.barcodemodel = res.Data;
            } else {
              this.$message.error(res.Msg);
            }
          });
      },
      // 货品打印
      async command(command) {
        console.log(this.data);
        if (command.split("-")[1] === "1") {
          //二维码
          await this.getqrcodemodel();
        } else if (command.split("-")[1] === "0") {
          //条形码
          await this.getbarcodemodel();
        }
        this.printId = command;
        this.GoodsPrint();
      },
      // 生成打印数据
      printChange(e) {
        let print = this.printData[e];
        // 开始结束初始值
        let Begin = print.Begin;
        let End =
          print.Nums > this.pagination.pageSize
            ? this.pagination.pageSize
            : print.End;
        // 分页赋值
        this.pagination.total = print.Nums;
        // 数据
        let arr = [];
        for (let i = Begin; i <= End; i++) {
          arr.push({
            Name: print.cInvName,
            QrCode: print.QrCode + "-" + (i + "").padStart(6, "0"),
          });
        }
        this.showPrintData = arr;
      },
      currentChange(page) {
        this.pagination.current = page;
        let print = this.printData[this.printValue];
        let Begin = print.Begin + (page - 1) * this.pagination.pageSize;
        let End =
          print.Nums > page * this.pagination.pageSize
            ? page * this.pagination.pageSize
            : print.End;
        // 数据
        let arr = [];
        for (let i = Begin; i <= End; i++) {
          arr.push({
            Name: print.cInvName,
            QrCode: print.QrCode + "-" + (i + "").padStart(6, "0"),
          });
        }
        this.showPrintData = arr;
      },
      GoodsPrint() {
        if (!this.printId) return this.$message.error("请选择打印模式");
        this.getPrintData(this.printId.split("-")[0], this.printId.split("-")[1]);
        this.dialogPrintVisible = false;
      },
      getPrintData(e, qrendbar) {
        this.qrendbar = "";
        this.qrendbar = qrendbar;
        this.printShowValue = e;
  
        if (qrendbar === "1") {
          this.printopenobj = JSON.parse(this.qrcodemodel.Content);
          if (e === "1") {
            //等于客户
            this.printopenlist = this.printopenobj.printlist.filter((item) => {
              return item.Key !== "平台二维码" && item.Key !== "国际二维码";
            });
          } else if (e === "0") {
            console.log(this.printopenobj);
            //等于自我
            this.printopenlist = this.printopenobj.printlist.filter((item) => {
              return item.Key !== "客户二维码" && item.Key !== "国际二维码";
            });
          } else if (e === "2") {
            console.log(this.printopenobj);
            //等于全部
            this.printopenlist = this.printopenobj.printlist;
          }
        } else if (qrendbar === "0") {
          this.printopenobj = JSON.parse(this.barcodemodel.Content);
          if (e === "1") {
            console.log(e, "客户");
            //等于客户
            this.printopenlist = this.printopenobj.printlist.filter((item) => {
              return item.Key !== "平台条形码" && item.Key !== "国际条形码";
            });
          } else if (e === "0") {
            console.log(this.printopenobj);
            //等于自我
            this.printopenlist = this.printopenobj.printlist.filter((item) => {
              return item.Key !== "客户条形码" && item.Key !== "国际条形码";
            });
          } else if (e === "2") {
            console.log(this.printopenobj);
            //等于全部
            this.printopenlist = this.printopenobj.printlist;
          }
        }
        console.log(this.printopenlist, "this.printopenlist");
        this.printData = this.data[0].datalist;
        console.log(this.data[0].datalist, "this.data");
        this.dialogGoods = true;
      },
      handleClose() {
        this.dialogVisible = false;
        this.dialogGoods = false;
      },
      creatQrCode(text) {
        if (this.$refs.qrCodeUrl) return;
        this.$nextTick(() => {
          var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text, // 需要转换为二维码的内容
            width: 200,
            height: 200,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        });
      },
      async getFormData() {
        await this.$http
          .post("/BO/BillList/GetBillDetail", { id: this.billid })
          .then((res) => {
            if (res.Success) {
              this.SignCode = res.Data.SignCode;
              this.codeTwo = res.Data.QrCode;
              this.danhao = res.Data.BillId;
              this.smsCode = res.Data.SmsCode;
              this.Sign = res.Data.Sign;
              this.Seal = res.Data.Seal;
              this.QrCode = res.Data.QrCode;
              this.countnums = res.Data.countnums;
              this.wights = res.Data.wights;
              this.sendMessage = res.Data.SendMessage;
              this.CustomerId = res.Data.CustomerId;
              this.phone = res.Data.CustomerPhone;
              this.Company = res.Data.Company;
              this.IdCard = res.Data.IdCard;
              this.ReceiptPhone = res.Data.ReceiptPhone;
              this.FullName = res.Data.FullName;
              this.SigningDate = res.Data.SigningDate;
              this.timeList = JSON.parse(res.Data.OrderLog);
              this.Status = res.Data.Status; // 订单状态
              this.entity = res.Data;
              this.cTime = res.Data.CreateTime?.substring(0, 10);
              if (res.Data.Content) {
                if (res.Data.Imgs) {
                  this.imglist = res.Data.Imgs.split(",");
                } else {
                  this.imglist = [];
                }
                this.$nextTick(() => {
                  var obj = JSON.parse(res.Data.Content);
                  this.printPages = obj.printPages;
                  this.styleW = obj.styleW;
                  this.styleH = obj.styleH;
                  this.printlist = obj.printlist;
                  this.printsize = obj.printsize;
                  this.isAccessory = obj.isAccessory;
                  if (this.printsize === "portrait") {
                    this.styleText =
                      '<style type="text/css" media="print">\n' +
                      "  @page { size: portrait; }\n" +
                      "</style>";
                  } else {
                    this.styleText =
                      '<style type="text/css" media="print">\n' +
                      "  @page { size: landscape; }\n" +
                      "</style>";
                  }
                  this.data = this.printlist.filter((item) => {
                    return item.type === "4";
                  });
                  var tablearr = JSON.parse(JSON.stringify(this.data));
                  var arr = [];
                  if (tablearr[0].rowCount) {
                    for (
                      var i = 0;
                      i < tablearr[0].datalist.length;
                      i += tablearr[0].rowCount
                    ) {
                      console.log(
                        tablearr[0].datalist.slice(i, i + tablearr[0].rowCount),
                        "数据"
                      );
                      arr.push(
                        tablearr[0].datalist.slice(i, i + tablearr[0].rowCount)
                      );
                    }
                  } else {
                    if (obj.keyType === "A4") {
                      for (var i = 0; i < tablearr[0].datalist.length; i += 10) {
                        arr.push(tablearr[0].datalist.slice(i, i + 10));
                      }
                    } else {
                      for (var i = 0; i < tablearr[0].datalist.length; i += 5) {
                        arr.push(tablearr[0].datalist.slice(i, i + 5));
                      }
                      console.log(arr);
                    }
                  }
  
                  var newarr = [];
  
                  arr.forEach((item) => {
                    var newobj = JSON.parse(res.Data.Content);
                    newobj.printlist.forEach((val) => {
                      if (val.Key == "发货日期") {
                        this.cTime = val.value;
                      }
                      if (val.type === "4") {
                        val.datalist = [];
                        this.$set(val, "datalist", item);
                        let length = val.datalist.length;
                        console.log(val);
                        if (val.rowCount) {
                          if (length < val.rowCount) {
                            for (let i = 0; i < val.rowCount - length; i++) {
                              val.datalist.push({});
                            }
                          }
                        } else {
                          if (obj.keyType === "A4") {
                            if (length < 10) {
                              for (let i = 0; i < 10 - length; i++) {
                                val.datalist.push({});
                              }
                            }
                          } else {
                            if (length < 5) {
                              for (let i = 0; i < 5 - length; i++) {
                                val.datalist.push({});
                              }
                            }
                          }
                        }
                        console.log(val.datalist, "datalist");
                      }
                    });
                    newarr.push(newobj);
                  });
                  this.printPages = arr.length;
                  this.newarr = newarr;
                });
              } else {
                this.$message.error("暂无信息");
              }
            } else {
              this.$message.error("数据获取失败!请刷新页面重试");
            }
          });
      },
      NumFormatting(data) {
        return NumFormatting(data);
      },
      // 实收数量
      realNums(list) {
        var arr = list.filter((item) => {
          return item.type === "4";
        });
        var num = 0;
        arr[0].datalist.forEach((item) => {
          if (item.nums) {
            num += Number(item.nums);
          }
        });
        return num.toFixed(2);
      },
      // 金额合计
      priceNums(list) {
        var arr = list.filter((item) => {
          return item.type === "4";
        });
        var num = 0;
        arr[0].datalist.forEach((item) => {
          if (item.SumiInvRCost) {
            num += Number(item.SumiInvRCost);
          }
        });
        return num.toFixed(2);
      },
      wightsfun(list) {
        var arr = list.filter((item) => {
          return item.type === "4";
        });
        var num = 0;
        arr[0].datalist.forEach((item) => {
          if (item.Weight1) {
            num = Number(item.Weight1) + num;
          }
        });
        return num.toFixed(2);
      },
      countnumsfun(list) {
        var arr = list.filter((item) => {
          return item.type === "4";
        });
        var num = 0;
        arr[0].datalist.forEach((item) => {
          if (item.Quantity) {
            num = Number(item.Quantity) + num;
          }
        });
        return num.toFixed(2);
      },
      sendSMS() {
        this.$http
          .post(`/BO/CustomerLinkman/CSSendSMS?Id=${this.billid}`)
          .then((res) => {
            if (res.Success) {
              this.SignCode = res.Data;
              this.$message.success("短信发送成功");
              clearInterval(this.timer);
              this.disabled = true;
              this.timer = setInterval(() => {
                this.oneMinute--;
                if (this.oneMinute <= 0) {
                  clearInterval(this.timer);
                  this.oneMinute = 60;
                  this.disabled = false;
                }
              }, 1000);
            } else {
              this.$alert(res.Msg, "提示", {
                confirmButtonText: "确定",
              });
            }
          });
      },
      timestampToTime(timestamp) {
        // 时间戳为10位需*1000，时间戳为13位不需乘1000
        var date = new Date(timestamp - 28800000);
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        var D =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
        var h = date.getHours() + ":";
        var m = date.getMinutes() + ":";
        var s = date.getSeconds();
        return Y + M + D + h + m + s;
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  @page {
    size: auto;
    margin: 0mm !important;
  }
  @media print {
    * {
      -webkit-print-color-adjust: exact !important;
      -moz-print-color-adjust: exact !important;
      -ms-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  }
  td {
    height: 24px;
  }
  .rightMain {
    line-height: 1.7;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding-bottom: 10px;
    color: #000;
    border-bottom: 1px solid #ccc;
  }
  .printBox {
    background: #fff;
    border-top: 1px solid #ccc;
    padding: 10px 40px;
    text-align: left;
    position: fixed;
    // width: 960px;
    bottom: 0;
    left: 0;
  }
  .SginImgBox {
    display: flex;
    align-items: center;
    height: 80px;
    width: 120px;
    .SginImg {
      transform: rotate(-90deg);
      width: 80px;
    }
  }
  
  .barcode {
    width: 100%;
    height: 100%;
  }
  /deep/ .vue-barcode-element {
    width: 100%;
    height: 100%;
  }
  </style>
  